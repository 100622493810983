.header {
    position: relative;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    width: 100%;
    background-color: #242323;
    border-bottom: #c6cad5 solid 12px;
    padding-right: 30px;
    z-index: 1;
}
.logo {
    font-family: 'Square Peg', cursive;
    width: 130px;
    border-right: #c6cad5 solid 12px;
    background-color: #eab70e;
    height: 100px;
    font-size: 90px;
    font-weight: 700;
    color: #13110e;
    line-height: 1.15;
    text-align: center;
}

.title {
    color: #ffffff;
    margin-left: 30px;
}
.menu-button {
  display: none;
}

.menu {
    margin-left: auto;
}

.header__nav-item:nth-child(n + 2) {
    margin-left: 20px;
}

.navlink {
font-size: 17px;
line-height: 20px;
color: #ffffff;
}

.navlink:nth-child(n + 2) {
    margin-left: 30px;
}

@media (max-width: 768px) {


.menu-button {
    display: block;
    margin-left: auto;
    padding: 0;
    border: 0;
    background-color: transparent;
}

.menu {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: calc(100% + 5px);
    right: 10px;
    width: 200px;
    padding: 25px;
    background-color: #fff;
    border: 2px solid #eab70e;
    border-radius: 7px;
    opacity: 0;
    transform: translateY(-10px);
    visibility: hidden;
    transition: 0.3s;
}
.menu.active {
    transform: translateY(0);
    opacity: 1;
    visibility: visible;
    z-index: 2;
}

.menu::before,
.menu::after {
    display: block;
    content: "";
    position: absolute;
    width: 20px;
    height: 20px;
    right: 15px;
    border: 10px solid transparent;
}

.menu::before {
    top: -20px;
    border-bottom: 10px solid #eab70e;
}

.menu::after {
    top: -19px;
    border-bottom: 10px solid #fff;
}

.navlink {
    color: #13110e;
    line-height: 1.8;
}
.navlink:nth-child(n + 2) {
    margin-left: 0px;
}
}