body {
  font-family: -apple-system, BlinkMacSystemFont, 'Lato', 'Rajdhani', sans-serif;
  color: #666666;
  font-size: 14px;
  line-height: 1.80857;
  font-weight: normal;
}

a {
  color: #1f1f1f;
  text-decoration: none;
  outline: none;
  transition: all .3s ease-in-out;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  position: relative;
  padding: 0;
  margin: 0
}

button {
  border: 0;
  margin: 0;
  padding: 0;
  cursor: pointer;
}

button:focus {
  outline: none;
}

ul,
li,
ol {
  margin: 0px;
  padding: 0px;
  list-style: none;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
}